import { createTheme, lighten, Theme } from "@mui/material/styles";

// Extend MUI's Palette and Theme interfaces to include title
declare module "@mui/material/styles" {
  interface Theme {
    title: string;
    browserTitle: string;
    loginLogo: string;
    headerLogo: string;
  }
  interface ThemeOptions {
    title?: string;
    browserTitle?: string;
    loginLogo?: string;
    headerLogo?: string;
  }
}

// Theme generator function
export const generateTheme = (settings: {
  title: string;
  browserTitle: string;
  headerColor: string;
  buttonColor: string;
  menuColor: string;
  loginLogoPath: string;
  headerLogoPath: string;
}): Theme =>
  createTheme({
    title: settings.title, // Set the title globally in the theme
    browserTitle: settings.browserTitle,
    loginLogo: settings.loginLogoPath, // Set the Login logo path globally in the theme
    headerLogo: settings.headerLogoPath, // Set the Header logo path globally in the theme
    palette: {
      // You can keep other standard MUI colors if needed
      //primary: { main: settings.buttonColor },
    },
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: settings.headerColor, // Custom header color
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          contained: {
            backgroundColor: settings.buttonColor, // Custom button color
            "&:hover": {
              backgroundColor: lighten(settings.buttonColor, 0.2), // Lighter Custom hover color
            },
          },
        },
      },
      // MuiListItem: {
      //   styleOverrides: {
      //     root: {
      //       "&:hover": {
      //         backgroundColor: settings.menuColor, // Hover color
      //       },
      //       "&.Mui-selected": {
      //         backgroundColor: settings.menuColor, // Active color
      //         "&:hover": {
      //           backgroundColor: settings.menuColor, // Active hover color
      //         },
      //       },
      //     },
      //   },
      // },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            height: 48,
            fontSize: "14px", // Set font size
            color: "#545861", // Default text color
            pl: 10,
            textTransform: "capitalize",
            // "&:hover": {
            //   backgroundColor: settings.menuColor, // Hover color
            // },
            "&.Mui-selected": {
              fontSize: "14.5px", // Set font size
              color: settings.menuColor,
              //backgroundColor: settings.menuColor, // Active background color
              // "&:hover": {
              //   backgroundColor: settings.menuColor, // Active hover color
              // },
            },
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            width: 22,
            height: 22,
            color: "inherit",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
        },
      },
    },
    // typography: {
    //   allVariants: {
    //     color: "inherit",
    //   },
    // },
  });
