import "../../utils/string.extension";
import { toast } from "react-toastify";
import { isEmpty } from "../../utils/Util";
import { useEffect } from "react";
import {
  EverestApi,
  TargetPayApi,
  DealerProfile,
} from "../../utils/UtilTarget";
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  useTheme,
} from "@mui/material";
import React from "react";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { Helmet } from "react-helmet-async";

export default function Terminals(props: any) {
  const [Terminals, SetTerminals] = React.useState<any>(null);
  const [counter, setCounter] = React.useState<any>(0);
  const dealerId = useAppSelector((state: RootState) => state.dealer.dealerId);
  const theme = useTheme();

  useEffect(() => {
    GetStoreTerminals();
  }, []);

  async function GetStoreTerminals() {
    var res = await TargetPayApi(
      "StoreTerminals",
      { dealerId: await DealerProfile("dealerId") },
      "get"
    );

    SetTerminals("");
    if (!isEmpty(res.data)) SetTerminals(res);
  }

  function OnTerminalChange(row: any, value: any) {
    if (row.OriginalValue === undefined)
      row.OriginalValue = row["terminalName"];
    row["terminalName"] = value;
    row.hasChanges = true;
    setCounter(counter + 1);
  }

  function hasRowChanges(row: any): any {
    if (row.OriginalValue === undefined) return false;
    return !(row.OriginalValue === row.terminalName);
  }

  function hasChanges(): any {
    if (isEmpty(Terminals) || isEmpty(Terminals.data)) return false;
    const result = Terminals.data.filter(
      (d) => d.OriginalValue != undefined && d.terminalName != d.OriginalValue
    );
    return result.length > 0;
  }

  async function SaveChanges() {
    const result = Terminals.data.filter((d) => d.hasChanges == true);
    let obj: any = new Object();
    var objBody = result.map(function (item) {
      return {
        id: item.id,
        terminalName: item.terminalName,
      };
    });

    obj.query = "PerseusPay.SaveTerminals";
    obj.queryBody = objBody;

    const res = await TargetPayApi(
      "query",
      JSON.stringify(obj),
      "post",
      "Everest"
    );

    if (!isEmpty(res)) {
      toast.success(res.Response);
      GetStoreTerminals();
    }
  }

  return (
    <>
      <Helmet>
        <title> Terminal Setup | {theme.browserTitle} </title>
      </Helmet>
      <Box display="flex" justifyContent="center" alignItems="center">
        {Terminals == null && (
          <Box>
            Please wait. Processing.....<CircularProgress></CircularProgress>{" "}
          </Box>
        )}
        {isEmpty(Terminals) && Terminals != null && (
          <Box style={{ padding: "10px 20px" }}>
            <h4>No Terminal found.</h4>
          </Box>
        )}
        {!isEmpty(Terminals) && (
          <Paper style={{ padding: "0px" }}>
            <Box
              style={{
                padding: "5px",
                fontSize: "20px",
                backgroundColor: "#EBEDEF",
              }}
            >
              Terminal Setup
            </Box>
            <Table size={"small"} className="sticky-header">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>Terminal Id</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    Terminal Alias
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    Last Activity
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Serial#</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!isEmpty(Terminals) &&
                  Terminals.data?.map((row: any, index: any) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>
                          {row.id}
                          <span style={{ color: "red" }}>
                            {hasRowChanges(row) ? " ***" : ""}
                          </span>{" "}
                        </TableCell>
                        <TableCell>
                          <TextField
                            style={{ width: "250px" }}
                            required
                            id="outlined-required"
                            size="small"
                            value={row.terminalName}
                            onChange={(e) =>
                              OnTerminalChange(row, e.target.value)
                            }
                            inputProps={{ style: { fontSize: "small" } }}
                          />
                        </TableCell>
                        <TableCell>
                          {isEmpty(row.lastActivityAt)
                            ? ""
                            : row.lastActivityAt.toUSDateFormat()}
                        </TableCell>
                        <TableCell>{row.serialNumber}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            <Box style={{ textAlign: "right", padding: "0px 10px 10px 0px" }}>
              <Button
                disabled={!hasChanges()}
                style={{ marginTop: "20px", textAlign: "right" }}
                variant="contained"
                onClick={(e) => SaveChanges()}
              >
                Save Changes
              </Button>
            </Box>
          </Paper>
        )}
      </Box>
    </>
  );
}
