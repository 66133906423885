import { isEmpty } from "../../utils/Util";
import Header from "../../layouts/simple/header";
import { Helmet } from "react-helmet-async";
import {
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  Toolbar,
  FormControlLabel,
  Switch,
  Box,
  useTheme,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect, useState } from "react";
import { useLoginMutation } from "../../services/API/authApiSlice";
import { AuthRequest } from "../../types/types";
import { useLocation, useNavigate } from "react-router-dom";
import { setCredentials } from "../../features/auth/authSlice";
import { setIsRemotePayment } from "../../features/dashboard/dashboardSlice";
import { toast } from "react-toastify";
import { useAppDispatch } from "../../app/hooks";
import { unescape } from "querystring";
import {
  DealerProfile,
  GetDealerSettings,
  TargetPayApi,
} from "../../utils/UtilTarget";
import { shouldLoadPageSettings } from "../../features/settings/transactionsettings.slice";
import TargetPay from "../../layouts/dashboard/nav/TargetPay.png";
import PerseusPay from "../../layouts/dashboard/nav/PerseusPay.png";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const [form, setForm] = useState<AuthRequest>({
    dmsDealerId: undefined,
    username: "",
    password: "",
  });

  const [login, { isLoading }] = useLoginMutation();
  const [isRequired, setIsRequired] = useState<any>(true);
  const [isTLDomainUser, setIsTLDomainUser] = useState<any>(false);
  const [userValidated, setUserValidated] = useState<any>(false);
  const [dmsDealerId, setDmsDealerId] = useState<any>("");
  const [imageError, setImageError] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const onboardDealerToken = searchParams.get("onboardDealerToken");

  useEffect(() => {
    if (onboardDealerToken) {
    }
  }, [onboardDealerToken]);

  const handleImageError = () => {
    setImageError(true);
  };

  //-------------------- Handlers --------------------//
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    // Regex to allow only positive integers
    const regex = /^\d*$/;

    if (name === "dmsDealerId") {
      // Check if the input matches the regex
      if (regex.test(value) || value === "") {
        setDmsDealerId(value);

        setForm((prevState) => ({
          ...prevState,
          [name]: Number(value),
        }));
      }
    } else {
      setForm((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSwitchChange = (e) => {
    setIsRequired(!e.target.checked);
    setIsTLDomainUser(e.target.checked);

    if (e.target.checked)
      setForm((prevState) => ({
        ...prevState,
        ["dmsDealerId"]: undefined,
      }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await login(form)
      .unwrap()
      .then(async (response) => {
        dispatch(
          setCredentials({
            access_token: response.access_token,
            expires_in: response.expires_in,
            refresh_token: response.refresh_token,
            token_type: response.token_type,
            scope: response.scope,
          })
        );
        setUserValidated(true);

        // reset the isRemotePayment global state
        dispatch(setIsRemotePayment(false));

        dispatch(shouldLoadPageSettings(true));

        // set token in localStorage
        let postLogin = localStorage.getItem("postLogin");
        if (isEmpty(postLogin) || postLogin == "/login")
          postLogin = localStorage.getItem("lastpostLogin");
        if (isEmpty(postLogin) || postLogin == "/login") postLogin = "/";
        localStorage.clear();
        localStorage.setItem("access_token", response.access_token);
        localStorage.setItem("refresh_token", response.refresh_token);
        localStorage.setItem("onboardDealer_token", onboardDealerToken);
        localStorage.setItem("isTLDomainUser", isTLDomainUser);
        if (postLogin != "/login") {
          localStorage.setItem("lastpostLogin", postLogin);
        }
        if (isTLDomainUser) {
          toast.success("Logged in successfully");
          // navigate to PreOnboarding
          setTimeout(() => {
            navigate("/dashboard/preonboarding");
          }, 500);
        } else {
          const res = await IsDealerOnboarded();
          if (res === false) {
            navigate("/401");
          } else {
            // Get Dealer Payment Gateway
            await GetDealerPaymentGateway();

            // Get Dealer Settings
            await GetDealerSettings(0);
            toast.success("Logged in successfully");
            // navigate to dashboard
            setTimeout(() => {
              navigate("/");
            }, 500);
          }
        }
      })
      .catch((error) => {
        console.log("Invalid Userame or Password", error);
        toast.error("Invalid Userame or Password");
      });
  };

  async function IsDealerOnboarded() {
    let _dealerId = await DealerProfile("dealerId");
    if (isEmpty(_dealerId)) {
      return false;
    }
    const res = await TargetPayApi(
      `GetPreOnBoardingLink?dealerId=${_dealerId}`,
      null,
      "get"
    );

    if (!isEmpty(res)) return true;
    else return false;
  }

  async function GetDealerPaymentGateway() {
    let _dealerId = await DealerProfile("dealerId");
    if (isEmpty(_dealerId)) {
      return false;
    }
    const res = await TargetPayApi(
      `GetDealerPaymentGateway?dealerId=${_dealerId}`,
      null,
      "get"
    );

    if (!isEmpty(res)) {
      localStorage.setItem("payment_gateway", res);
    }
  }

  return (
    <>
      <Helmet>
        <title> Login | {theme.browserTitle} </title>
      </Helmet>

      <Header />
      <Toolbar />
      <Toolbar />

      <Container>
        <Grid container>
          {/* Left Empty Grid */}
          <Grid item xs={12} md={4} />

          {/* Center Grid */}
          <Grid item xs={12} md={4}>
            <Typography variant="h6" textAlign={"center"} gutterBottom>
              <img
                src={imageError ? PerseusPay : theme.loginLogo}
                onError={handleImageError}
                style={{ height: "50px", marginBottom: "60px" }}
              />
            </Typography>

            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                sx={{ mb: "10px" }}
                label="DMS Dealer Id"
                name="dmsDealerId"
                required={isRequired}
                value={dmsDealerId}
                onChange={handleChange}
                style={{
                  visibility: isRequired ? "visible" : "hidden",
                }}
                size="small"
                variant="outlined"
                autoFocus
              />

              <TextField
                fullWidth
                sx={{ mb: "10px" }}
                label="Username"
                name="username"
                required
                value={form.username}
                onChange={handleChange}
                size="small"
                type="text"
                variant="outlined"
              />

              <TextField
                fullWidth
                sx={{ mb: "15px" }}
                label="Password"
                name="password"
                required
                value={form.password}
                onChange={handleChange}
                size="small"
                type="password"
                variant="outlined"
              />

              <Button
                fullWidth
                variant="contained"
                type="submit"
                disabled={isLoading || userValidated}
                endIcon={
                  isLoading || userValidated ? (
                    <CircularProgress size="1.5rem" />
                  ) : null
                }
              >
                <>{isLoading || userValidated ? "Please wait" : "Login"}</>
              </Button>
              <Box style={{ textAlign: "right", marginTop: "10px" }}>
                <FormControlLabel
                  control={<Switch />}
                  label={<span>Login as Internal User</span>}
                  style={{ marginRight: "0px" }}
                  onChange={handleSwitchChange}
                />
              </Box>
            </form>
          </Grid>

          {/* Right Empty Grid */}
          <Grid item xs={12} md={4} />
        </Grid>
      </Container>
    </>
  );
};

export default Login;
