// src/setupDatadog.js
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

const initializeDatadog = () => {
  const clientToken = process.env.REACT_APP_DATADOG_CLIENT_TOKEN;
  const service = process.env.REACT_APP_DATADOG_SERVICE;
  const env = process.env.REACT_APP_ENV;
  const sessionSampleRate = parseInt(process.env.REACT_APP_DATADOG_SESSION_SAMPLE_RATE || "100");
  const sessionReplaySampleRate = parseInt(process.env.REACT_APP_DATADOG_SESSION_REPLAY_SESSION_RATE || "100");
  const site = process.env.REACT_APP_DATADOG_SITE as "datadoghq.com" | "us3.datadoghq.com" | "us5.datadoghq.com" | "datadoghq.eu" | "ddog-gov.com" | "ap1.datadoghq.com";  
  datadogLogs.init({
    clientToken,
    env,
    site: site,
    service,
    forwardErrorsToLogs: true,
    forwardConsoleLogs: 'all',
    sessionSampleRate: 100,
  });

  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APPLICATIONID,
    clientToken,
    site: site,
    service,
    env,
    sessionSampleRate,
    sessionReplaySampleRate,
    defaultPrivacyLevel: 'mask-user-input',
    trackUserInteractions: true,
  });

  datadogLogs.logger.info("DataDog logger and RUM initialized successfully");
};
export { initializeDatadog };
