import "../../utils/string.extension";
import { useEffect, useState } from "react";
import { endOfDay, startOfMonth, subMonths } from "date-fns";
import { Stack } from "@mui/system";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { DateRangePickerCustom } from "../../common/components/daterangepicker.custom";
import DashboardMatrix from "./dashboard.matrix";
import DashboardRecentTransactions from "./dashboard.recenttransactions";
import DashboardCardBrandUsage from "./dashboard.cardbrandusage";
import { DashboardDataLoader as DataLoader } from "./dashboard.dataloader";
import { DealerLocationFilter } from "./dashboard.dealerlocationfilter";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setTransactionSettings } from "../../features/settings/transactionsettings.slice";
import { RootState } from "../../app/store";
import { shouldLoadPageSettings } from "../../features/settings/transactionsettings.slice";

export default function DashboardPerseusPay(props) {
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const loadPageSettings = useAppSelector(
    (state: RootState) => state.transactionSettings.shouldLoadPageSettings
  );

  const today = new Date();
  const [matrixData, setMatrixData] = useState<any>([]);
  const [recentsTransactions, setRecentsTransactions] = useState<any>([]);
  const [cardBrandUsages, setCardBrandUsages] = useState<any>();
  const [dealerLocations, setDealerLocations] = useState<any>([]);
  const [dealerLocationsID, setDealerLocationID] = useState<number>(-1);
  const [selectedDateRange, setSelectedDateRange] = useState<[Date, Date]>();
  const defaultDateRange = [startOfMonth(today), endOfDay(today)];

  useEffect(() => {
    DataLoader.GetDealerLocations(props.DealerId).then((response) => {
      setDealerLocations(response);
    });

    if (loadPageSettings) {
      DataLoader.GetPageSettings().then((response) => {
        dispatch(setTransactionSettings(response));
        dispatch(shouldLoadPageSettings(false));
      });
    }
  }, []);

  useEffect(() => {
    const dateCriteria = !selectedDateRange
      ? defaultDateRange
      : selectedDateRange;

    DataLoader.GetTransactionMatrix(dateCriteria, dealerLocationsID, 10).then(
      (response) => {
        setMatrixData(response?.length > 0 ? response[0] : {});
      }
    );

    DataLoader.GetCardBrandUsageData(dateCriteria, dealerLocationsID).then(
      (response) => {
        setCardBrandUsages(response);
      }
    );

    DataLoader.GetPayments(dateCriteria, dealerLocationsID, 10).then(
      (response) => {
        setRecentsTransactions(response);
      }
    );
  }, [dealerLocationsID, selectedDateRange]);

  const handleDateRangePickerChange = (value: [Date, Date]) => {
    setSelectedDateRange(value);
  };

  const handleDealerLocationChange = (value) => {
    setDealerLocationID(value);
  };

  return (
    <>
      <Helmet>
        <title> Dashboard | {theme.browserTitle} </title>
      </Helmet>
      <Box>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
          style={{ border: "0px solid red", margin: "0px" }}
        >
          <Typography variant="h6" gutterBottom style={{ fontWeight: "bold" }}>
            Dashboard
          </Typography>
          <Stack direction="row" alignItems="center">
            <DealerLocationFilter
              dealerLocationsID={dealerLocationsID}
              dealerLocations={dealerLocations}
              onDealerLocationChange={handleDealerLocationChange}
            ></DealerLocationFilter>
            <DateRangePickerCustom
              dateRange={defaultDateRange}
              onDateRangePickerChange={handleDateRangePickerChange}
            ></DateRangePickerCustom>
          </Stack>
        </Stack>
        <DashboardMatrix paymentData={matrixData}></DashboardMatrix>
      </Box>
      <br />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <DashboardRecentTransactions
            transactions={recentsTransactions}
          ></DashboardRecentTransactions>
        </Grid>

        {/* Hide Card Brand Usage Section for time being. */}
        {/* Don't Uncomment/Delete */}
        {/* <Grid item xs={4}>
          <DashboardCardBrandUsage
            cardBrandUsages={cardBrandUsages}
          ></DashboardCardBrandUsage>
        </Grid> */}
      </Grid>
    </>
  );
}
