// src/components/SessionReplayControl.js
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

const SessionReplayControl = () => { //session replay control on page level.
  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname;

    if (currentPath.startsWith("/payment")) {
      datadogRum.startSessionReplayRecording();
      datadogLogs.logger.info(`Starting session replay for ${currentPath} Page`);
    } else {
      datadogRum.stopSessionReplayRecording();
      datadogLogs.logger.info(`skipping session replay for ${currentPath} page`);
    }
  }, [location]);

  return null;
};

export default SessionReplayControl;
