import * as SignalR from "@microsoft/signalr";
import { toast } from "react-toastify";
import { RootState } from "../../../app/store";
import { Navigate, useNavigate } from "react-router-dom";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  Popover,
  Select,
  TextField,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";
import React from "react";
import { isEmpty } from "../../../utils/Util";

import {
  DealerProfile,
  GetPaymentMethods,
  GetDealerPaymentMethods,
  TargetPayApi,
  formatUSPhoneNo,
  isValidUSPhoneNo,
} from "../../../utils/UtilTarget";
import { useAppSelector } from "../../../app/hooks";
import InputMask from "react-input-mask";
import { StyleCreator } from "../../../helpers/stylecreator";
import { FormStyleCollection as formStyles } from "../../../styles/form";

let DealerGuid = "";
let lastState = "";
let lastZipCode = "";
let initDealerData = null;
export default function OnBoarding() {
  const navigate = useNavigate();
  const theme = useTheme();

  const ReviewInfo =
    "Review and edit the information below. (Settings can't be changed later)";

  const [signalRconnection, setSignalRConnection] = useState<any>(null);
  const dealerId = useAppSelector((state: RootState) => state.dealer.dealerId);
  const [DealerData, SetDealerData] = React.useState<any>([]);
  const [isValidOnboardToken, setIsValidOnboardToken] =
    React.useState<any>(true);
  const [PaymentMethods, SetPaymentMethods] = React.useState<any>([]);
  const [counter, setCounter] = React.useState<any>(0);
  const [AcceptTC, setAcceptTC] = React.useState<any>("Continue");

  const [isHostedOnboardingStart, setIsHostedOnboardingStart] =
    React.useState<any>(false);
  const [isProcessing, setIsProcessing] = React.useState<any>(false);
  const [showPopOver, SetShowPopOver] = React.useState(false);
  const [confirmOnboarding, SetConfirmOnboarding] = React.useState(false);
  const [anchorPopOver, setAnchorPopOver] = React.useState(null);
  const [onBoardingValue, SetOnBoardingValue] = React.useState("");
  const [country, setCountry] = React.useState("US");
  const [zipcodeLabel, setZipCodeLabel] = React.useState("Zip Code");
  const [DealerPaymentMethods, setDealerPaymentMethods] = React.useState<any>(
    []
  );
  const [countryCities, setCountryCities] = React.useState<any>([]);
  const [countryStates, setCountryStates] = React.useState<any>([]);
  const classes = StyleCreator.makeStyles(formStyles.all);

  let onboardDealer_token = "";

  const styleDone = {
    marginLeft: "10px",
    color: "green",
    fontWeight: "bold",
    fontSize: "15px",
  };

  useEffect(() => {
    DealerGuid = "";
    lastState = "";
    lastZipCode = "";
    initDealerData = null;

    document.title = `Onboard a Customer | ${theme.browserTitle}`;
    GetDealer("", dealerId);
    GetPaymentMethod();
    onboardDealer_token = localStorage.getItem("onboardDealer_token");
  }, []);

  useEffect(() => {
    if (country == "US") {
      setZipCodeLabel("Zip Code");
    }
    // else {
    //   setZipCodeLabel("Postal Code");
    // }
    GetCountryStates();
  }, [country]);

  async function makeSignalRConnection(referenceNumber: any) {
    const uid = referenceNumber;
    const negotiateRequestBody = { uid, dealerId };
    const negotiateRequestHeader = { headers: { "x-ms-signalr-userid": uid } };
    try {
      let url = "https://azurefunctionkg.azurewebsites.net/api/negotiate";
      let negotiateResponse: any = await axios.post(
        url,
        negotiateRequestBody,
        negotiateRequestHeader
      );
      let signalRConnection: any = new SignalR.HubConnectionBuilder()
        .withUrl(negotiateResponse.data.url, {
          accessTokenFactory: () => negotiateResponse.data.accessToken,
        })
        .withAutomaticReconnect()
        .build();
      setSignalRConnection(signalRConnection);
    } catch (error) {
      console.error("Error: SignalR negotiation failed: ", error);
    }
  }

  useEffect(() => {
    if (signalRconnection) {
      if (signalRconnection._connectionState != "Connected") {
        console.log(
          "SignalR connectionState.0: " + signalRconnection._connectionState
        );
        signalRconnection
          .start()
          .then(() => {
            console.log(
              "SignalR connectionState.1: " + signalRconnection._connectionState
            );
            signalRconnection.on("newMessage", (message) => {
              GetDealer("", dealerId);
            });
          })
          .catch((error) => console.log(error));
      }
    }
  }, [signalRconnection]);

  async function ValidateOnboardDealerToken(dealerId: any) {
    if (
      onboardDealer_token == "null" ||
      onboardDealer_token == null ||
      onboardDealer_token == ""
    ) {
      return;
    } else {
      let obj: any = new Object();
      obj.DealerId = dealerId;
      obj.Token = onboardDealer_token;
      let jsonString = JSON.stringify(obj);

      const res = await TargetPayApi("ValidatePreOnBoardingLink", jsonString);

      return res;
    }
  }

  async function GetDealer(dealerGuid: any, dealerId: any) {
    if (isEmpty(dealerId)) dealerId = await DealerProfile("dealerId");
    if (isEmpty(dealerGuid) && isEmpty(dealerId)) return;

    const resToken: any = await ValidateOnboardDealerToken(dealerId);

    if (resToken == false) {
      setIsValidOnboardToken(false);
      navigate("/401");
      return;
    }

    let obj: any = new Object();
    let objBody: any = new Object();

    objBody.Tag = "Select";
    objBody.DealerGuid = dealerGuid;
    objBody.DealerId = dealerId;

    obj.query = "PerseusPay.DealerSetup";
    obj.queryBody = objBody;

    const res = await TargetPayApi(
      "query",
      JSON.stringify(obj),
      "post",
      "Everest"
    );

    if (!isEmpty(res)) {
      res.NameDBA = res.Name;
      if (res.VerificationStatus == "valid") {
        setAcceptTC("Terms & Conditions");
        setIsHostedOnboardingStart(false);
      }
      await GetCountryCities(res.StateOrProvince);
      if (isEmpty(initDealerData)) initDealerData = Object.assign({}, res);

      if (isEmpty(res.LegalEntityId)) {
        res.AccountHolderReference = "";
        res.AccountHolderDescription = "";
      }

      if (!isEmpty(res.LegalEntityId)) await GetLegalEntities(res);
      if (!isEmpty(res) && isEmpty(res.SplitConfigurationId))
        res.SplitConfigurationId = "ChooseOne";

      if (!isEmpty(res.Phone)) {
        if (!isValidUSPhoneNo(res.Phone)) {
          res.Phone = formatUSPhoneNo(res.Phone);
        }
      }

      // Get Dealer Payment Methods
      const response = await GetDealerPaymentMethods(dealerId, res.StoreId);

      if (!isEmpty(response)) setDealerPaymentMethods(response);
      else setDealerPaymentMethods(PaymentMethods);

      SetDealerData(res);
      if (isEmpty(DealerGuid)) DealerGuid = res.DealerGuid;

      if (!isEmpty(res.LegalEntityId)) {
        if (signalRconnection == null && res.VerificationStatus != "valid") {
          makeSignalRConnection(res.LegalEntityId);
        }
      }
      if (res.VerificationStatus == "valid") {
        if (res.VerificationStatus != initDealerData.VerificationStatus) {
          toast.dismiss();
          toast.success(
            "Onboarding process completed. Please refresh the screen to see the changes.",
            {
              position: toast.POSITION.TOP_CENTER,
              autoClose: false,
              closeOnClick: true,
              style: { width: "365px" },
            }
          );
        }
      }
    }
    return res;
  }

  // Get Payment Method
  async function GetPaymentMethod() {
    const response = await GetPaymentMethods();
    if (!isEmpty(response)) SetPaymentMethods(response);
  }

  async function GetLegalEntities(dealerData: any) {
    const resLegalEntities = await TargetPayApi(
      `LegalEntities?legalEntityId=${dealerData.LegalEntityId}`,
      null,
      "get"
    );
    if (!isEmpty(resLegalEntities)) {
      if (!isEmpty(resLegalEntities.organization)) {
        dealerData.Name = resLegalEntities.organization.legalName;
      }
    }
  }

  async function GetCountryCities(countryState = null, search = null) {
    let obj: any = new Object();
    let objBody: any = new Object();

    objBody.Country = country;
    objBody.State = DealerData.StateOrProvince;
    objBody.Search = search;
    if (countryState != null) objBody.State = countryState;

    obj.query = "CountryCities";
    obj.queryBody = objBody;

    const res = await TargetPayApi(
      "query",
      JSON.stringify(obj),
      "post",
      "Everest"
    );

    if (!isEmpty(res)) setCountryCities(res);
    lastState = obj.State;
  }

  async function GetCountryStates() {
    let obj: any = new Object();
    let objBody: any = new Object();

    objBody.Country = country;

    obj.query = "CountryStates";
    obj.queryBody = objBody;

    const res = await TargetPayApi(
      "query",
      JSON.stringify(obj),
      "post",
      "Everest"
    );

    if (!isEmpty(res)) {
      setCountryStates(res);
    }
  }

  //remove leading and trailing spaces from the complete string on blur event.
  const trimSpaces = (value: string, key: string) => {
    let dealerDataCopy = { ...DealerData };
    dealerDataCopy[key] = value.trim();
    SetDealerData(dealerDataCopy);
  };

  async function OnTextChange(value: any, key: any) {
    DealerData[key] = value;
    if (key == "ZipPostal" && value.length >= 5) {
      GetZip(value);
    }
    if (key == "StateOrProvince") {
      await GetCountryCities(value);
      DealerData.City = "";
    }
    setCounter(counter + 1);
  }

  function GetSelectedCity(City: any) {
    if (isEmpty(City)) return "";
    const city = countryCities.find(
      ({ PrimaryCity }) => PrimaryCity.toLowerCase() === City.toLowerCase()
    );
    return city?.PrimaryCity || "";
  }

  async function GetZip(zip: any) {
    let zipCode = zip.replaceAll("_", "");
    if (zipCode.length < 5) return;
    let iDash = zipCode.indexOf("-");
    if (iDash > 0) zipCode = zipCode.substring(0, iDash);
    if (zipCode.length < 5) return;
    let obj: any = new Object();
    let objBody: any = new Object();

    objBody.zip = zipCode;
    if (zipCode == lastZipCode) return;

    obj.query = "ZipCode";
    obj.queryBody = objBody;

    const res = await TargetPayApi(
      "query",
      JSON.stringify(obj),
      "post",
      "Everest"
    );

    if (!isEmpty(res)) {
      lastZipCode = zipCode;
      DealerData.TimeZone = res.Timezone;
      DealerData.City = res.PrimaryCity;
      DealerData.StateOrProvince = res.State;
      setCountry(res.Country);
      if (DealerData.StateOrProvince != lastState) await GetCountryCities();
    }
    setCounter(counter + 2);
  }

  function CheckComplete(column: any): any {
    try {
      let val = DealerData[column];
      if (val == null || val == "") return "";
      if (column == "VerificationStatus") {
        if (val == "valid") return <>&#x2713;</>;
        return "";
      }
      if (val != "") return <>&#x2713;</>;
    } catch (error) {
      return "";
    }
  }

  async function HostedOnBoarding() {
    localStorage.setItem("onboardDealer_token", "");
    const res = await TargetPayApi("CreateOnBoardingLink", DealerData);
    if (!isEmpty(res)) {
      window.open(res, "_blank");
    }
    const timer = setTimeout(() => {}, 5000);
    setIsHostedOnboardingStart(true);

    clearTimeout(timer);
    return;
  }

  function CheckData(): any {
    if (
      isEmpty(DealerData.SplitConfigurationId) ||
      DealerData.SplitConfigurationId == "ChooseOne"
    ) {
      alert("Invalid input. Missing pricing model");
      return false;
    }
    let PaymentCodes = "";
    for (let i = 0; i < DealerPaymentMethods.length; i++) {
      PaymentCodes += DealerPaymentMethods[i].code + ",";
    }
    DealerData.Country = country;
    DealerData.PaymentMethods = PaymentCodes;

    if (
      DealerData.AccountHolderReference == null ||
      DealerData.AccountHolderReference == ""
    ) {
      alert("Invalid input. Missing Legal Company Name");
      return false;
    }

    if (
      DealerData.AccountHolderDescription == null ||
      DealerData.AccountHolderDescription == ""
    ) {
      alert("Invalid input. Missing Doing Business As");
      return false;
    }

    if (DealerData.Address == null || DealerData.Address == "") {
      alert("Invalid input. Missing Address");
      return false;
    }

    if (DealerData.Country == null || DealerData.Country == "") {
      alert("Invalid input. Missing Country");
      return false;
    }

    if (DealerData.ZipPostal == null || DealerData.ZipPostal == "") {
      alert("Invalid input. Missing Zip Code");
      return false;
    }

    if (DealerData.City == null || DealerData.City == "") {
      alert("Invalid input. Missing City");
      return false;
    }

    if (
      DealerData.StateOrProvince == null ||
      DealerData.StateOrProvince == ""
    ) {
      alert("Invalid input. Missing State");
      return false;
    }

    if (DealerData.Phone == null || DealerData.Phone == "") {
      alert("Invalid input. Missing phone number");
      return false;
    }
    return true;
  }

  async function InitiateOnBoarding(e: any) {
    toast.dismiss();
    SetOnBoardingValue("");
    if (!CheckData()) return;
    SetConfirmOnboarding(true);
    SetShowPopOver(true);
    setAnchorPopOver(e.currentTarget);
  }

  async function ProcessOnboarding(e: any) {
    if (!CheckData()) return;
    if (parseInt(onBoardingValue) != DealerData.DmsDealerId) {
      toast.error("Please type " + DealerData.DmsDealerId + " to continue", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
        closeOnClick: true,
      });
      return;
    }

    SetConfirmOnboarding(false);
    SetShowPopOver(false);

    toast.dismiss();
    setIsProcessing(true);
    var res = await TargetPayApi("OnBoardDealer", DealerData);
    setIsProcessing(false);
    if (isEmpty(res)) return;
    if (!isEmpty(res.errorDetail)) {
      DealerData.LegalEntityId = res.LegalEntityId;
      DealerData.BalanceAccountId = res.BalanceAccountId;
      DealerData.AccountHolderId = res.AccountHolderId;
      DealerData.BusinessLineId = res.BusinessLineId;
      setCounter(counter + 1);
      toast.error(res.errorDetail, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
        closeOnClick: true,
      });

      return;
    }

    SetDealerData(res.data);
    if (res.data != null && res.data.Error != null && res.data.Error != "") {
      SetShowPopOver(true);
      setIsProcessing(false);
      return;
    }

    GetDealer(DealerGuid, DealerData.DealerId);
  }

  function FormatAdyenResponse(obj: any): any {
    let myJSON = JSON.stringify(obj);
    myJSON = myJSON.replaceAll("Adyen", "[PerseusPay]");
    myJSON = myJSON.replaceAll("adyen", "[PerseusPay]");
    return JSON.parse(myJSON);
  }

  return (
    <Box style={{ border: "0px solid red" }}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{ padding: "0px", paddingRight: "0px" }}
      >
        {isEmpty(DealerData) && (
          <Box>
            Please wait. Processing.....<CircularProgress></CircularProgress>{" "}
          </Box>
        )}
      </Box>

      {!isEmpty(DealerData) &&
        (isEmpty(DealerData.IndustryCode) ||
          isEmpty(DealerData.SplitConfigurationId) ||
          DealerData.SplitConfigurationId == "ChooseOne" ||
          isValidOnboardToken == false) && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ padding: "0px", paddingRight: "0px" }}
          >
            <Box>
              <Navigate to="/401" />
            </Box>
          </Box>
        )}

      {DealerData &&
        DealerData.DealerId != null &&
        !isEmpty(DealerData.IndustryCode) &&
        !isEmpty(DealerData.SplitConfigurationId) &&
        DealerData.SplitConfigurationId != "ChooseOne" && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ padding: "0px", paddingRight: "0px" }}
          >
            <table style={{ border: "0px solid red" }}>
              <tbody>
                <tr>
                  <td valign="top" style={{ border: "0px solid green" }}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      style={{ padding: "0px" }}
                    >
                      <Box style={{ display: "block" }}>
                        <Paper>
                          <Box
                            style={{
                              margin: "5px 5px 0px 15px",
                              border: "0px solid red",
                            }}
                          >
                            {DealerData != null && (
                              <Box style={{ fontSize: "small" }}>
                                <br />
                                <Box
                                  style={{
                                    fontSize: "small",
                                    color:
                                      !isEmpty(DealerData) &&
                                      isEmpty(DealerData.StoreReference)
                                        ? "black"
                                        : "gray",
                                  }}
                                >
                                  <i>{ReviewInfo}</i>
                                </Box>
                                <Box style={formStyles.fieldSpacer}>
                                  <TextField
                                    label="Legal Company Name"
                                    className={classes.root}
                                    variant="standard"
                                    disabled={
                                      !isEmpty(DealerData.StoreReference)
                                    }
                                    fullWidth
                                    required
                                    id="outlined-required"
                                    value={DealerData.AccountHolderReference}
                                    onChange={(e) =>
                                      OnTextChange(
                                        e.target.value,
                                        "AccountHolderReference"
                                      )
                                    }
                                    onBlur={(e) => {
                                      trimSpaces(
                                        e.target.value,
                                        "AccountHolderReference"
                                      );
                                    }}
                                    inputProps={{
                                      style: { fontSize: "small" },
                                    }}
                                  />
                                </Box>
                                <Box style={formStyles.fieldSpacer}>
                                  <TextField
                                    label="Doing Business As"
                                    className={classes.root}
                                    variant="standard"
                                    disabled={
                                      !isEmpty(DealerData.StoreReference)
                                    }
                                    fullWidth
                                    required
                                    id="outlined-required"
                                    value={DealerData.AccountHolderDescription}
                                    onChange={(e) =>
                                      OnTextChange(
                                        e.target.value,
                                        "AccountHolderDescription"
                                      )
                                    }
                                    onBlur={(e) => {
                                      trimSpaces(
                                        e.target.value,
                                        "AccountHolderDescription"
                                      );
                                    }}
                                    inputProps={{
                                      style: { fontSize: "small" },
                                    }}
                                  />
                                </Box>
                                <Box style={formStyles.fieldSpacer}>
                                  <TextField
                                    label="Address"
                                    variant="standard"
                                    disabled={
                                      !isEmpty(DealerData.StoreReference)
                                    }
                                    style={{ marginTop: "5px" }}
                                    fullWidth
                                    required
                                    id="outlined-required"
                                    className={classes.root}
                                    value={DealerData.Address}
                                    onChange={(e) =>
                                      OnTextChange(e.target.value, "Address")
                                    }
                                    onBlur={(e) => {
                                      trimSpaces(e.target.value, "Address");
                                    }}
                                    inputProps={{
                                      style: { fontSize: "small" },
                                    }}
                                  />
                                </Box>
                                <Box style={formStyles.fieldSpacer}>
                                  <FormControl>
                                    <InputLabel
                                      variant="standard"
                                      id="demo-simple-select-label"
                                      sx={{ fontSize: "large" }}
                                      style={{
                                        color: !isEmpty(
                                          DealerData.StoreReference
                                        )
                                          ? "rgba(0, 0, 0, 0.38)"
                                          : "rgba(0, 0, 0, 0.6)",
                                      }}
                                    >
                                      Country *
                                    </InputLabel>
                                    <Select
                                      disabled={
                                        !isEmpty(DealerData.StoreReference)
                                      }
                                      variant="standard"
                                      required
                                      sx={{ fontSize: "small", width: "550px" }}
                                      fullWidth
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={country}
                                      label="Country"
                                      onChange={(e) =>
                                        setCountry(e.target.value)
                                      }
                                    >
                                      <MenuItem
                                        sx={{ fontSize: "small" }}
                                        value={"US"}
                                      >
                                        United States
                                      </MenuItem>
                                      {/* <MenuItem
                                        sx={{ fontSize: "small" }}
                                        value={"CAN"}
                                      >
                                        Canada
                                      </MenuItem> */}
                                    </Select>
                                  </FormControl>
                                </Box>
                                <Box style={formStyles.fieldSpacer}>
                                  {country == "US" && (
                                    <InputMask
                                      mask="99999"
                                      alwaysShowMask={false}
                                      disabled={
                                        !isEmpty(DealerData.StoreReference)
                                      }
                                      value={DealerData.ZipPostal}
                                      onChange={(e) =>
                                        OnTextChange(
                                          e.target.value,
                                          "ZipPostal"
                                        )
                                      }
                                    >
                                      {() => (
                                        <TextField
                                          disabled={
                                            !isEmpty(DealerData.StoreReference)
                                          }
                                          label={zipcodeLabel}
                                          variant="standard"
                                          fullWidth
                                          required
                                          id="outlined-required"
                                          className={classes.maskTextField}
                                        />
                                      )}
                                    </InputMask>
                                  )}
                                  {/* {country != "US" && (
                                  <InputMask
                                    mask="a9a 9a9"
                                    alwaysShowMask={false}
                                    disabled={
                                      !isEmpty(DealerData.StoreReference)
                                    }
                                    value={DealerData.ZipPostal}
                                    onChange={(e) =>
                                      OnTextChange(e.target.value, "ZipPostal")
                                    }
                                  >
                                    {() => (
                                      <TextField
                                        disabled={
                                          !isEmpty(DealerData.StoreReference)
                                        }
                                        label={zipcodeLabel}
                                        variant="standard"
                                        fullWidth
                                        required
                                        id="outlined-required"
                                        className={classes.maskTextField}
                                      />
                                    )}
                                  </InputMask>
                                )} */}
                                </Box>
                                <Box style={formStyles.fieldSpacer}>
                                  <FormControl>
                                    <InputLabel
                                      variant="standard"
                                      id="demo-simple-select-label"
                                      sx={{ fontSize: "large" }}
                                      style={{
                                        color: !isEmpty(
                                          DealerData.StoreReference
                                        )
                                          ? "rgba(0, 0, 0, 0.38)"
                                          : "rgba(0, 0, 0, 0.6)",
                                      }}
                                    >
                                      City *
                                    </InputLabel>
                                    <Select
                                      disabled={
                                        !isEmpty(DealerData.StoreReference)
                                      }
                                      variant="standard"
                                      required
                                      sx={{ fontSize: "small", width: "550px" }}
                                      fullWidth
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={GetSelectedCity(DealerData.City)}
                                      onChange={(e) =>
                                        OnTextChange(e.target.value, "City")
                                      }
                                    >
                                      {countryCities.map(
                                        (row: any, index: number) => {
                                          return (
                                            <MenuItem
                                              sx={{ fontSize: "small" }}
                                              value={row.PrimaryCity}
                                              key={index}
                                            >
                                              {row.PrimaryCity}
                                            </MenuItem>
                                          );
                                        }
                                      )}
                                    </Select>
                                  </FormControl>
                                </Box>
                                <Box style={formStyles.fieldSpacer}>
                                  <FormControl>
                                    <InputLabel
                                      variant="standard"
                                      id="demo-simple-select-label"
                                      sx={{ fontSize: "large" }}
                                      style={{
                                        color: !isEmpty(
                                          DealerData.StoreReference
                                        )
                                          ? "rgba(0, 0, 0, 0.38)"
                                          : "rgba(0, 0, 0, 0.6)",
                                      }}
                                    >
                                      State *
                                    </InputLabel>
                                    <Select
                                      disabled={
                                        !isEmpty(DealerData.StoreReference)
                                      }
                                      variant="standard"
                                      required
                                      sx={{ fontSize: "small", width: "550px" }}
                                      fullWidth
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={DealerData.StateOrProvince}
                                      onChange={(e) =>
                                        OnTextChange(
                                          e.target.value,
                                          "StateOrProvince"
                                        )
                                      }
                                    >
                                      {countryStates.map(
                                        (row: any, index: number) => {
                                          return (
                                            <MenuItem
                                              sx={{ fontSize: "small" }}
                                              value={row.Code}
                                              key={index}
                                            >
                                              {row.Name}
                                            </MenuItem>
                                          );
                                        }
                                      )}
                                    </Select>
                                  </FormControl>
                                </Box>
                                <Box style={formStyles.fieldSpacer}>
                                  <InputMask
                                    mask="+1 (999) 999 9999"
                                    alwaysShowMask={false}
                                    disabled={
                                      !isEmpty(DealerData.StoreReference)
                                    }
                                    value={DealerData.Phone}
                                    onChange={(e) =>
                                      OnTextChange(e.target.value, "Phone")
                                    }
                                  >
                                    {() => (
                                      <TextField
                                        disabled={
                                          !isEmpty(DealerData.StoreReference)
                                        }
                                        label="Phone Number"
                                        variant="standard"
                                        fullWidth
                                        required
                                        id="outlined-required"
                                        className={classes.maskTextField}
                                      />
                                    )}
                                  </InputMask>
                                </Box>
                                <Box
                                  style={{
                                    padding: "20px 10px 10px 0px",
                                    textAlign: "left",
                                  }}
                                >
                                  <table>
                                    <tbody>
                                      <tr>
                                        <td>
                                          {DealerData &&
                                            (isEmpty(
                                              DealerData.StoreReference
                                            ) ||
                                              isEmpty(
                                                DealerData.PaymentMethodsDateCreated
                                              )) &&
                                            DealerData.SplitConfigurationId !=
                                              "ChooseOne" && (
                                              <Button
                                                style={{ marginRight: "10px" }}
                                                disabled={
                                                  showPopOver || isProcessing
                                                }
                                                variant="contained"
                                                onClick={(e) =>
                                                  InitiateOnBoarding(e)
                                                }
                                              >
                                                Initiate onboarding
                                              </Button>
                                            )}
                                        </td>
                                        <td>
                                          {isProcessing && (
                                            <CircularProgress
                                              size={25}
                                              style={{ marginLeft: "20px" }}
                                              color="info"
                                            />
                                          )}
                                        </td>
                                        <td>
                                          {!isEmpty(
                                            DealerData.StoreReference
                                          ) &&
                                            !isEmpty(
                                              DealerData.PaymentMethodsDateCreated
                                            ) &&
                                            !isProcessing && (
                                              <Button
                                                variant="contained"
                                                onClick={(e) =>
                                                  HostedOnBoarding()
                                                }
                                              >
                                                {AcceptTC}
                                              </Button>
                                            )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </Box>
                              </Box>
                            )}
                          </Box>
                          <Box
                            style={{
                              border: "0px solid green",
                              padding: "5px",
                            }}
                          >
                            {DealerData.VerificationProblems != null &&
                              DealerData.PaymentMethodsDateCreated != null &&
                              isHostedOnboardingStart == true && (
                                <Box style={{ display: "table" }}>
                                  <Paper
                                    style={{
                                      margin: "0px 10px",
                                      padding: "10px",
                                      fontSize: "small",
                                      color: "red",
                                    }}
                                  >
                                    <h6>
                                      Click on the continue button to fix the
                                      errors below
                                    </h6>
                                    {DealerData.VerificationProblems.map(
                                      (row: any, index: number) => {
                                        return (
                                          <Box
                                            style={{ display: "table" }}
                                            key={index}
                                          >
                                            {row.verificationErrors.map(
                                              (row: any, index: number) => {
                                                return (
                                                  <Box
                                                    style={{ display: "table" }}
                                                    key={index}
                                                  >
                                                    {row.code}: {row.message}
                                                  </Box>
                                                );
                                              }
                                            )}
                                          </Box>
                                        );
                                      }
                                    )}
                                  </Paper>
                                </Box>
                              )}
                          </Box>
                        </Paper>
                      </Box>
                    </Box>
                  </td>
                  <td valign="top" style={{ paddingTop: "5px" }}>
                    <Paper
                      style={{ margin: "0px 20px 0px 5px", marginLeft: "10px" }}
                    >
                      <Box
                        style={{
                          borderLeft: "0px solid silver",
                          whiteSpace: "nowrap",
                          fontSize: "small",
                          padding: "5px 10px",
                        }}
                      >
                        <Box>
                          Legal Entity
                          <span style={styleDone}>
                            {CheckComplete("LegalEntityId")}
                          </span>
                        </Box>
                        <Box style={{ padding: "4px 0px" }}>
                          Account Holder
                          <span style={styleDone}>
                            {CheckComplete("AccountHolderId")}
                          </span>
                        </Box>
                        <Box style={{ padding: "4px 0px" }}>
                          Balance Account
                          <span style={styleDone}>
                            {CheckComplete("BalanceAccountId")}
                          </span>
                        </Box>
                        <Box style={{ padding: "4px 0px" }}>
                          Business Line
                          <span style={styleDone}>
                            {CheckComplete("BusinessLineId")}
                          </span>
                        </Box>
                        <Box style={{ padding: "4px 0px" }}>
                          Create Store
                          <span style={styleDone}>
                            {CheckComplete("StoreId")}
                          </span>
                        </Box>
                        <Box style={{ padding: "4px 0px" }}>
                          Payment Methods
                          <span style={styleDone}>
                            {CheckComplete("PaymentMethodsDateCreated")}
                          </span>
                        </Box>
                        <Box style={{ padding: "4px 0px" }}>
                          Terms & Conditions
                          <span style={styleDone}>
                            {CheckComplete("VerificationStatus")}
                          </span>
                        </Box>
                        <Box style={{ padding: "4px 0px" }}>
                          Payout Schedule
                          <span style={styleDone}>
                            {CheckComplete("SweepId")}
                          </span>
                        </Box>
                      </Box>
                    </Paper>
                    {!isEmpty(DealerData.StoreReference) &&
                      !isEmpty(DealerPaymentMethods) && (
                        <Paper style={{ margin: "10px 20px 0px 10px" }}>
                          {DealerPaymentMethods.map(
                            (row: any, index: number) => {
                              const imagePath = `/images/PM_${row.code}.png`;
                              return (
                                <React.Fragment key={index}>
                                  <img
                                    src={imagePath}
                                    className="paymentMethod-logo"
                                    key={index}
                                  />
                                  {(index + 1) % 3 === 0 && <br />}
                                </React.Fragment>
                              );
                            }
                          )}
                        </Paper>
                      )}
                  </td>
                </tr>
              </tbody>
            </table>
            <Popover
              style={{ marginTop: "15px" }}
              open={showPopOver}
              anchorEl={anchorPopOver}
              onClose={(e) => SetShowPopOver(false)}
              anchorOrigin={{
                vertical: "center",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <Box>
                <div
                  style={{
                    padding: "0px 0px",
                    minWidth: "200px",
                    minHeight: "100px",
                  }}
                >
                  {confirmOnboarding && (
                    <Box style={{ fontSize: "small" }}>
                      <Box
                        style={{
                          borderBottom: "1px solid silver",
                          padding: "3px 5px",
                          backgroundColor: "#F8F9F9",
                        }}
                      >
                        Confirm Onboarding
                      </Box>
                      <Box style={{ padding: "10px 20px" }}>
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                Please type{" "}
                                <span style={{ fontWeight: "bold" }}>
                                  {DealerData.DmsDealerId}
                                </span>{" "}
                                to continue
                              </td>
                              <td>
                                <TextField
                                  autoComplete="off"
                                  autoFocus
                                  placeholder={DealerData.DmsDealerId.toString()}
                                  style={{
                                    marginTop: "5px",
                                    marginLeft: "10px",
                                  }}
                                  required
                                  id="outlined-required"
                                  className={classes.root}
                                  value={onBoardingValue}
                                  size="small"
                                  onChange={(e) =>
                                    SetOnBoardingValue(e.target.value)
                                  }
                                  inputProps={{ style: { fontSize: "small" } }}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={2}>
                                <Box
                                  style={{
                                    textAlign: "right",
                                    padding: "10px",
                                  }}
                                >
                                  <Button
                                    variant="contained"
                                    onClick={(e) => ProcessOnboarding(e)}
                                  >
                                    continue onboarding
                                  </Button>
                                  <Button
                                    style={{ marginLeft: "10px" }}
                                    variant="contained"
                                    onClick={(e) => SetShowPopOver(false)}
                                  >
                                    Cancel
                                  </Button>
                                </Box>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </Box>
                    </Box>
                  )}

                  {DealerData.AdyenResponse && (
                    <Box>
                      <Box
                        style={{
                          borderBottom: "1px solid silver",
                          padding: "3px 5px",
                          backgroundColor: "#F8F9F9",
                          fontWeight: "bold",
                        }}
                      >
                        Error:
                      </Box>
                      <Box style={{ padding: "0px 10px" }}>
                        <pre>
                          {JSON.stringify(
                            FormatAdyenResponse(DealerData.AdyenResponse),
                            null,
                            2
                          )}
                        </pre>
                      </Box>
                    </Box>
                  )}
                </div>
              </Box>
            </Popover>
          </Box>
        )}
    </Box>
  );
}
