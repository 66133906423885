import React, { useEffect } from "react";
import App from "./App";
import "./index.css";
import { createRoot } from "react-dom/client";
import { BrowserRouter, useLocation } from "react-router-dom";
import { Provider } from "react-redux";
import { persistor, store } from "./app/store";
import { PersistGate } from "redux-persist/integration/react";
import { initializeDatadog } from "./DataDogConfig/setupDatadog";
import SessionReplayControl from "./DataDogConfig/DataDogSessionReplayControl";

initializeDatadog(); //initializing datadog for ReactJs

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
  // <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <SessionReplayControl />
          <App />
        </PersistGate>
      </Provider>
    </BrowserRouter>
  // </React.StrictMode>
);
