import { addDays, endOfDay, startOfDay, subMonths, subWeeks } from "date-fns";
import moment from 'moment'
import {
  DealerProfile,
  EverestApi,
  TargetPayApi,
} from "../../utils/UtilTarget";
import {
  endOfDayWithoutTimezone,
  startOfDayWithoutTimezone,
} from "../../utils/Util";

export class DashboardDataLoader {
  public static async GetPayments(
    dateCriteria: any,
    dealerLocationsID: number,
    pageSize: Number
  ): Promise<any> {
    try {
      let obj: any = new Object();
      let objBody: any = new Object();

      obj.query = "PerseusPay.Payments";

      if (dealerLocationsID == -1) {
        objBody.CompanyDealerId = await DealerProfile("companyDealerId");
      } else {
        objBody.DealerId = dealerLocationsID;
      }

      objBody.Search = "";
      objBody.PageNumber = 0;
      objBody.PageSize = pageSize;
      
      if (!dateCriteria || dateCriteria.length === 0 || dateCriteria[0] === '' || dateCriteria[1] === '') {
        console.error("Date criteria is invalid or missing.");
      } else {
        let startDate = moment(dateCriteria[0])
        let endDate = moment(dateCriteria[1])

        if (!startDate.isValid() || !endDate.isValid()) {
          console.error("One or both dates are invalid.");
        } else {
          endDate.set({ hour: 23, minute: 59, second: 59 });

          objBody.StartDate = startDate.utc().format();
          objBody.EndDate = endDate.utc().format();
        }
      }

      obj.queryBody = JSON.stringify(objBody);

      const response = await TargetPayApi(
        "query",
        JSON.stringify(obj),
        "post",
        "Everest"
      );
      return response;
    } catch (error) {
      alert(error);
    }
  }

  public static async GetTransactionMatrix(
    dateCriteria: any,
    dealerLocationsID: number,
    pageSize: Number
  ): Promise<any> {
    try {
      let obj: any = new Object();

      if (dealerLocationsID == -1) {
        obj.CompanyDealerID = await DealerProfile("companyDealerId");
      } else {
        obj.DealerID = dealerLocationsID;
      }
      obj.FromDate = dateCriteria[0];
      obj.ToDate = dateCriteria[1];

      var resp = await TargetPayApi(`Matrix`, obj, "Get", "Dashboard");
      return resp;
    } catch (error) {
      alert(error);
    }
  }

  public static async GetDealerLocations(dealerId): Promise<any> {
    try {
      let obj: any = new Object();
      let objBody: any = new Object();

      obj.query = "GetDealer";
      objBody.DealerId = dealerId;

      obj.queryBody = JSON.stringify(objBody);

      const response = await TargetPayApi(
        "query",
        JSON.stringify(obj),
        "post",
        "Everest"
      );
      return response;
    } catch (error) {
      alert(error);
    }
  }

  public static async GetCardBrandUsageData(
    dateCriteria: any,
    dealerLocationsID: number
  ): Promise<any> {
    try {
      let obj: any = new Object();

      if (dealerLocationsID == -1) {
        obj.CompanyDealerID = await DealerProfile("companyDealerId");
      } else {
        obj.DealerID = dealerLocationsID;
      }
      obj.FromDate = dateCriteria[0];
      obj.ToDate = dateCriteria[1];

      var resp = await TargetPayApi(`CardBrandUsage`, obj, "Get", "Dashboard");
      return resp;
    } catch (error) {
      alert(error);
    }
  }

  public static async GetPageSettings(): Promise<any> {
    try {
      var resp = await TargetPayApi(
        "GetByPage/transaction",
        undefined,
        "Get",
        "PageSettings"
      );
      return resp;
    } catch (error) {
      alert(error);
    }
  }
}
